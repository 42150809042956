import { MyDataHelps } from '@careevolution/mydatahelps-ui';
import React from 'react';
import ReactDOM from 'react-dom';
import {
	BrowserRouter as Router,
	Routes,
	Route
} from "react-router-dom";
import Home from './views/Home';

var myDataHelps = (window as any).MyDataHelps as MyDataHelps;

if (window.location.hostname === "localhost") {
	myDataHelps.setParticipantAccessToken({ "access_token": "1192b024a428dcb09590f6cc3c3ab7be", "expires_in": 21600, "token_type": "Bearer" }, "https://mydatahelps.org/");
}

ReactDOM.render(
	<React.StrictMode>
		<Router>
			<Routes>
				<Route path="/home/" element={<Home />} />
			</Routes>
		</Router>
	</React.StrictMode>,
	document.getElementById('root')
);