import React, { useEffect, useRef, useState } from 'react'
import { Card, FaceSelector, MyDataHelps } from "@careevolution/mydatahelps-ui"
import getDayKey from '../../helpers/get-day-key';
import DayListItem from '../DayListItem/DayListItem';
import { add, isSameDay } from 'date-fns';
import "./Correlator.css"
import { MetricConfiguration } from '../../types/MetricConfiguration';
import WeekInReview from '../WeekInReview/WeekInReview';
import { queryMetrics } from '../../helpers/query-metrics';
import { summarizeMetrics } from '../../helpers/summarize-metrics';
import TitleBar from '../TitleBar/TitleBar';
const myDataHelps = require("@careevolution/mydatahelps-js").default as MyDataHelps

interface CorrelatorProps {
	metrics: MetricConfiguration[];
	selectedDate: Date;
	onDateSelected(d: Date): void;
}

export default function (props: CorrelatorProps) {
	var currentDate = new Date();
	currentDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 0, 0, 0, 0);
	const [metricsLookup, setMetricsLookup] = useState<{ [key: string]: { [key: string]: number } }>({});
	const [wellbeingLookup, setWellbeingLookup] = useState<{ [key: string]: number }>({});
	const [loadedDaysStart, setLoadedDaysStart] = useState<Date>(add(currentDate, { days: 1 }));
	const [loading, setLoading] = useState(false);
	const requestID = useRef<number>(0);

	function loadFirstPage() {
		//load the 40 days before the selected date in order to fill out the week in review
		//and calculate 30 day averages for the current dates
		var loadStart = add(currentDate, { days: -40 });
		setLoading(true);

		requestID.current++;
		var currentRequestID = requestID.current;
		queryMetrics(loadStart, add(currentDate, { days: 1 }), props.metrics).then(function (result) {
			if (requestID.current != currentRequestID) { return; }
			setWellbeingLookup(result.wellbeingByDay);
			setMetricsLookup(result.metricsByDay);
			setLoading(false);
			setLoadedDaysStart(loadStart);
		});
	}

	function loadNextPage() {
		if (loading) { return; }
		var loadStart = add(loadedDaysStart, { days: -7 });
		setLoading(true);

		requestID.current++;
		var currentRequestID = requestID.current;
		queryMetrics(loadStart, loadedDaysStart, props.metrics).then(function (result) {
			if (requestID.current != currentRequestID) { return; }
			setWellbeingLookup({ ...wellbeingLookup, ...result.wellbeingByDay });
			setMetricsLookup({ ...metricsLookup, ...result.metricsByDay });
			setLoading(false);
			setLoadedDaysStart(loadStart);
		});
	}

	//if the actual set of available metrics changes, reset the whole view
	useEffect(() => {
		console.log(props.metrics);
		setLoadedDaysStart(add(currentDate, { days: 1 }));
		setMetricsLookup({});
		loadFirstPage();
	}, [props.metrics]);

	useEffect(() => {
		myDataHelps.on("applicationDidBecomeVisible", loadFirstPage);
		myDataHelps.on("externalAccountSyncComplete", loadFirstPage);
		return () => {
			myDataHelps.off("applicationDidBecomeVisible", loadFirstPage);
			myDataHelps.off("externalAccountSyncComplete", loadFirstPage);
		}
	}, []);


	var listDates: Date[] = [];
	var date = currentDate;
	while (date >= add(loadedDaysStart, { days: 33 })) {
		listDates.push(date);
		date = add(date, { days: -1 });
	}

	var summarizedMetrics = summarizeMetrics(listDates, metricsLookup, props.metrics);

	function updateWellbeingScore(date: Date, face: number) {
		var wellbeingCopy = { ...wellbeingLookup };
		wellbeingCopy[getDayKey(date)] = face;
		setWellbeingLookup(wellbeingCopy);
		myDataHelps.persistDeviceData([{
			startDate: getDayKey(date) + "T00:00:00+00:00",
			observationDate: getDayKey(add(date, { days: 1 })) + "T00:00:00+00:00",
			value: face.toString(),
			type: "DailyWellbeing"
		}]);
	}

	return (
		<div className="correlator">
			<WeekInReview selectedDate={props.selectedDate}
				averageFillPercent={summarizedMetrics.averageFillPercent}
				displayedDates={listDates}
				onDaySelected={(d) => props.onDateSelected(d)}
				metricConfigurations={props.metrics}
				metricValues={summarizedMetrics.dailyMetrics}
				onLoadMore={() => loadNextPage()}
				wellbeingLookup={wellbeingLookup}
				loading={loading} />
			<TitleBar date={props.selectedDate} />
			{loadedDaysStart < props.selectedDate &&
				<Card>
					<div className="face-selector">
						{isSameDay(props.selectedDate, new Date()) &&
							<div className="intro">How are you feeling overall today?</div>
						}
						{!isSameDay(props.selectedDate, new Date()) &&
							<div className="intro">How were you feeling overall?</div>
						}
						<FaceSelector selectedFaceValue={wellbeingLookup[getDayKey(props.selectedDate)]} onFaceSelected={(face: number) => updateWellbeingScore(props.selectedDate, face)} />
					</div>
				</Card>
			}
			{summarizedMetrics.dailyMetrics[getDayKey(props.selectedDate)] &&
				<DayListItem key={props.selectedDate.getTime()}
					averageFillPercent={summarizedMetrics.averageFillPercent}
					metricConfigurations={props.metrics}
					metricValues={summarizedMetrics.dailyMetrics[getDayKey(props.selectedDate)]}
				/>
			}
		</div>
	)
}