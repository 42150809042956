import React, { useEffect, useState } from 'react'
import { Layout, LoadingIndicator, MyDataHelps, SegmentedControl, ShinyOverlay, StatusBarBackground } from "@careevolution/mydatahelps-ui"
import metricsConfiguration from '../helpers/metrics';
import "./Home.css"
import Correlator from '../components/Correlator/Correlator';
import loadAvailableMetrics from '../helpers/load-available-metrics';
import { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBed, faHeartbeat, faPersonRunning } from '@fortawesome/free-solid-svg-icons';
const myDataHelps = require("@careevolution/mydatahelps-js").default as MyDataHelps

export default function () {
	var currentDate = new Date();
	currentDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 0, 0, 0, 0);
	const [loading, setLoading] = useState(true);
	const [selectedDate, setSelectedDate] = useState(currentDate);
	const [selectedSource, setSelectedSource] = useState<string | null>(null);
	const [selectedCategory, setSelectedCategory] = useState<"Summary" | "Sleep" | "Heart" | "Activity">("Summary");
	const [availableMetrics, setAvailableMetrics] = useState<string[]>([]);

	function load() {
		loadAvailableMetrics().then(function (metrics) {
			setAvailableMetrics(metrics);
			setLoading(false);
		});
	}

	useEffect(() => {
		load();
		myDataHelps.on("applicationDidBecomeVisible", load);
		myDataHelps.on("externalAccountSyncComplete", load);
		return () => {
			myDataHelps.off("applicationDidBecomeVisible", load);
			myDataHelps.off("externalAccountSyncComplete", load);
		}
	}, []);

	function getFinalMetrics() {
		if (!availableMetrics.length) {
			return [];
		}
		return metricsConfiguration.filter((m) => availableMetrics.indexOf(m.key) != -1 && m.source == getSelectedSource() && m.categories.indexOf(selectedCategory) != -1);
	}

	var availableMetricConfigurations = metricsConfiguration.filter((m) => availableMetrics.indexOf(m.key) != -1);
	var sources: { key: string, title: string }[] = [];
	var categories: { key: string, title: string }[] = [];
	if (availableMetricConfigurations.length) {
		var sourceLookup: { [key: string]: string } = {};
		availableMetricConfigurations.forEach((m) => sourceLookup[m.source] = m.source);
		sources = Object.keys(sourceLookup).map((k) => {
			return {
				key: sourceLookup[k],
				title: sourceLookup[k]
			};
		}).reverse();

		var categoryLookup: { [key: string]: string } = {};
		metricsConfiguration.filter((m) => m.source == getSelectedSource()).forEach((m) => m.categories.forEach((c) => categoryLookup[c] = c));
		categories = Object.keys(categoryLookup).map((k) => {
			return {
				key: categoryLookup[k],
				title: categoryLookup[k]
			};
		});
	}

	function getSelectedSource() {
		if (selectedSource == null) {
			return sources[0].key;
		}
		return selectedSource;
	}

	var finalMetrics = useMemo(() => getFinalMetrics(), [selectedCategory, selectedSource, availableMetrics]);

	return (
		<Layout>
			<StatusBarBackground color="#FFF" />
			<div className="header">
				<div className="switchers">
					{!loading && sources.length > 0 &&
						<div className="category-switcher">
							{sources.map((c) =>
								<div className={"category" + (getSelectedSource() == c.key ? " selected" : "")} onClick={() => setSelectedSource(c.key as any)}>
									{c.key}
									<ShinyOverlay />
								</div>
							)}
						</div>
					}
					{!loading && categories.length > 0 &&
						<div className="category-switcher">
							{categories.map((c) =>
								<div className={"category" + (selectedCategory == c.key ? " selected" : "")} onClick={() => setSelectedCategory(c.key as any)}>
									{(c.key == "Summary" || c.key == "Sleep") &&
										<FontAwesomeIcon icon={faBed} />
									}
									{(c.key == "Summary" || c.key == "Activity") &&
										<FontAwesomeIcon icon={faPersonRunning} />
									}
									{(c.key == "Summary" || c.key == "Heart") &&
										<FontAwesomeIcon icon={faHeartbeat} />
									}
									<ShinyOverlay />
								</div>
							)}
						</div>
					}
				</div>
			</div>
			{loading &&
				<LoadingIndicator />
			}
			{!loading &&
				<Correlator selectedDate={selectedDate} onDateSelected={(d) => setSelectedDate(d)} metrics={finalMetrics} />
			}
		</Layout>
	)
}