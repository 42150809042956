import { DeviceDataPoint, DeviceDataQueryParameters, Guid, MyDataHelps } from '@careevolution/mydatahelps-ui';
const myDataHelps = require("@careevolution/mydatahelps-js").default as MyDataHelps

export default function queryAllDeviceData(parameters: DeviceDataQueryParameters) {
	var allDeviceDataPoints: DeviceDataPoint[] = [];
	var queryPage = function (pageID?: Guid): Promise<DeviceDataPoint[]> {
		var queryParameters = { ...parameters };
		if (pageID) {
			queryParameters.pageID = pageID;
		}
		return myDataHelps.queryDeviceData(queryParameters).then(function (result) {
			allDeviceDataPoints = allDeviceDataPoints.concat(result.deviceDataPoints);
			if (result.nextPageID) {
				return queryPage(result.nextPageID);
			} else {
				return allDeviceDataPoints;
			}
		});
	}
	return queryPage();
}