import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import { faBed, faHeartbeat, faPersonRunning, faShoePrints, faStairs } from '@fortawesome/free-solid-svg-icons';
import { appleHealthFlightsClimbedDataProvider, appleHealthHeartRateRangeDataProvider, appleHealthHrvDataProvider, appleHealthInBedDataProvider, appleHealthMaxHeartRateDataProvider, appleHealthRestingHeartRateDataProvider, appleHealthSleepDataProvider, appleHealthStandTimeDataProvider, appleHealthStepsDataProvider, combinedStepsDataProvider, fitbitActivityMinutesDataProvider, fitbitFloorsDataProvider, fitbitHeartRateZonesDataProvider, fitbitRestingHeartRateDataProvider, fitbitSleepDataProvider, fitbitStepsDataProvider } from './daily-device-data-providers';
import { MetricConfiguration } from '../types/MetricConfiguration';
import { MyDataHelps } from '@careevolution/mydatahelps-ui';
import appleHealthWalkingHeartRateAverage from './daily-device-data-providers/apple-health-walking-heart-rate-average';
const myDataHelps = require("@careevolution/mydatahelps-js").default as MyDataHelps

var metricsConfiguration: MetricConfiguration[] = [
	{
		key: "AppleHealthSleep",
		dataProvider: appleHealthSleepDataProvider,
		label: "Sleep Time",
		source: "Apple Health",
		color: "#7b88c6",
		formatter: function (number: number) {
			var hours = Math.floor(number / 60);
			var displayValue = hours + "h " + Math.round(number - (hours * 60)) + "m";
			return displayValue;
		},
		icon: <FontAwesomeIcon icon={faBed} />,
		defaultDisabled: false,
		hasAnyData() {
			return myDataHelps.queryDeviceData({ namespace: "AppleHealth", type: "SleepAnalysisInterval" }).then(function (result) {
				return result.deviceDataPoints.length > 0;
			})
		},
		categories: ["Summary", "Sleep"]
	},
	{
		key: "AppleHealthInBed",
		dataProvider: appleHealthInBedDataProvider,
		label: "In Bed Time",
		source: "Apple Health",
		color: "#7b88c6",
		formatter: function (number: number) {
			var hours = Math.floor(number / 60);
			var displayValue = hours + "h " + Math.round(number - (hours * 60)) + "m";
			return displayValue;
		},
		icon: <FontAwesomeIcon icon={faBed} />,
		defaultDisabled: true,
		hasAnyData() {
			return myDataHelps.queryDeviceData({ namespace: "AppleHealth", type: "SleepAnalysisInterval" }).then(function (result) {
				return result.deviceDataPoints.length > 0;
			})
		},
		categories: ["Sleep"]
	},
	{
		key: "FitbitSleep",
		dataProvider: fitbitSleepDataProvider,
		label: "Sleep Time",
		source: "Fitbit",
		color: "#7b88c6",
		formatter: function (number: number) {
			var hours = Math.floor(number / 60);
			var displayValue = hours + "h " + Math.round(number - (hours * 60)) + "m";
			return displayValue;
		},
		icon: <FontAwesomeIcon icon={faBed} />,
		defaultDisabled: false,
		hasAnyData() {
			return myDataHelps.queryDeviceData({ namespace: "Fitbit", type: ["SleepLevelRem", "SleepLevelLight", "SleepLevelDeep", "SleepLevelAsleep"] }).then(function (result) {
				return result.deviceDataPoints.length > 0;
			})
		},
		categories: ["Summary", "Sleep"]
	},
	{
		key: "AppleHealthSteps",
		dataProvider: appleHealthStepsDataProvider,
		label: "Steps",
		source: "Apple Health",
		color: "#f5b722",
		formatter: function (number: number) {
			return Math.floor(number).toLocaleString()
		},
		icon: <FontAwesomeIcon rotate={270} icon={faShoePrints} />,
		defaultDisabled: false,
		hasAnyData() {
			return myDataHelps.queryDeviceData({ namespace: "AppleHealth", type: "HourlySteps" }).then(function (result) {
				return result.deviceDataPoints.length > 0;
			})
		},
		categories: ["Summary", "Activity"]
	},
	{
		key: "FitbitSteps",
		dataProvider: fitbitStepsDataProvider,
		label: "Steps",
		source: "Fitbit",
		color: "#f5b722",
		formatter: function (number: number) {
			return Math.floor(number).toLocaleString()
		},
		icon: <FontAwesomeIcon rotate={270} icon={faShoePrints} />,
		defaultDisabled: false,
		hasAnyData() {
			return myDataHelps.queryDeviceData({ namespace: "Fitbit", type: "Steps" }).then(function (result) {
				return result.deviceDataPoints.length > 0;
			})
		},
		categories: ["Summary", "Activity"]
	},
	{
		key: "AppleHealthFlightsClimbed",
		dataProvider: appleHealthFlightsClimbedDataProvider,
		label: "Flights Climbed",
		source: "Apple Health",
		color: "#f5b722",
		formatter: function (number: number) {
			return Math.round(number).toString()
		},
		icon: <FontAwesomeIcon rotate={270} icon={faStairs} />,
		defaultDisabled: true,
		hasAnyData() {
			return myDataHelps.queryDeviceData({ namespace: "AppleHealth", type: "FlightsClimbed" }).then(function (result) {
				return result.deviceDataPoints.length > 0;
			});
		},
		categories: ["Activity"]
	},
	{
		key: "FitbitTotalActivityMinutes",
		dataProvider: fitbitActivityMinutesDataProvider,
		label: "Activity",
		source: "Fitbit",
		color: "#f5b722",
		formatter: function (number: number) {
			return Math.floor(number).toString() + " minutes";
		},
		icon: <FontAwesomeIcon icon={faPersonRunning} />,
		defaultDisabled: false,
		hasAnyData() {
			return myDataHelps.queryDeviceData({ namespace: "Fitbit", type: ["MinutesVeryActive", "MinutesFairlyActive", "MinutesLightlyActive"] }).then(function (result) {
				return result.deviceDataPoints.length > 0;
			})
		},
		categories: ["Summary", "Activity"]
	},
	{
		key: "FitbitFloors",
		dataProvider: fitbitFloorsDataProvider,
		label: "Floors Climbed",
		source: "Fitbit",
		color: "#f5b722",
		formatter: function (number: number) {
			return Math.round(number).toString();
		},
		icon: <FontAwesomeIcon icon={faStairs} />,
		defaultDisabled: true,
		hasAnyData() {
			return myDataHelps.queryDeviceData({ namespace: "Fitbit", type: ["Floors"] }).then(function (result) {
				return result.deviceDataPoints.length > 0;
			})
		},
		categories: ["Activity"]
	},
	{
		key: "AppleHealthStandTime",
		dataProvider: appleHealthStandTimeDataProvider,
		label: "Stand Time",
		source: "Apple Health",
		color: "#f5b722",
		formatter: function (number: number) {
			return Math.floor(number).toString() + " minutes";
		},
		icon: <FontAwesomeIcon icon={faPersonRunning} />,
		defaultDisabled: true,
		hasAnyData() {
			return myDataHelps.queryDeviceData({ namespace: "AppleHealth", type: ["HourlyStandTime"] }).then(function (result) {
				return result.deviceDataPoints.length > 0;
			})
		},
		categories: ["Activity"]
	},
	{
		key: "AppleHealthMaxHeartRate",
		dataProvider: appleHealthMaxHeartRateDataProvider,
		label: "Max Heart Rate",
		source: "Apple Health",
		color: "#e35c33",
		formatter: function (number: number) {
			return Math.floor(number).toString() + " bpm";
		},
		icon: <FontAwesomeIcon icon={faHeartbeat} />,
		defaultDisabled: false,
		hasAnyData() {
			return myDataHelps.queryDeviceData({ namespace: "AppleHealth", type: ["HourlyMaximumHeartRate"] }).then(function (result) {
				return result.deviceDataPoints.length > 0;
			})
		},
		categories: ["Summary", "Heart"]
	},
	{
		key: "FitbitElevatedHeartRateMinutes",
		dataProvider: fitbitHeartRateZonesDataProvider,
		label: "Elevated Heart Rate",
		source: "Fitbit",
		color: "#e35c33",
		formatter: function (number: number) {
			return Math.floor(number).toString() + " minutes";
		},
		icon: <FontAwesomeIcon icon={faHeartbeat} />,
		defaultDisabled: false,
		hasAnyData() {
			return myDataHelps.queryDeviceData({ namespace: "Fitbit", type: ["HeartRateZone"] }).then(function (result) {
				return result.deviceDataPoints.length > 0;
			})
		},
		categories: ["Summary", "Heart"]
	},
	{
		key: "FitbitRestingHeartRate",
		dataProvider: fitbitRestingHeartRateDataProvider,
		label: "Resting Heart Rate",
		source: "Fitbit",
		color: "#e35c33",
		formatter: function (number: number) {
			return Math.floor(number).toString() + " bpm";
		},
		icon: <FontAwesomeIcon icon={faHeartbeat} />,
		defaultDisabled: true,
		hasAnyData() {
			return myDataHelps.queryDeviceData({ namespace: "Fitbit", type: ["RestingHeartRate"] }).then(function (result) {
				return result.deviceDataPoints.length > 0;
			})
		},
		categories: ["Summary", "Heart"]
	},
	{
		key: "AppleHealthHeartRateVariability",
		dataProvider: appleHealthHrvDataProvider,
		label: "Heart Rate Variability",
		source: "Apple Health",
		color: "#5db37e",
		formatter: function (number: number) {
			return Math.floor(number).toString();
		},
		icon: <FontAwesomeIcon icon={faHeartbeat} />,
		defaultDisabled: true,
		hasAnyData() {
			return myDataHelps.queryDeviceData({ namespace: "AppleHealth", type: ["HeartRateVariability"] }).then(function (result) {
				return result.deviceDataPoints.length > 0;
			})
		},
		categories: ["Heart"]
	},
	{
		key: "AppleHealthHeartRestingHeartRate",
		dataProvider: appleHealthRestingHeartRateDataProvider,
		label: "Resting Heart Rate",
		source: "Apple Health",
		color: "#5db37e",
		formatter: function (number: number) {
			return Math.floor(number).toString() + " bpm";
		},
		icon: <FontAwesomeIcon icon={faHeartbeat} />,
		defaultDisabled: true,
		hasAnyData() {
			return myDataHelps.queryDeviceData({ namespace: "AppleHealth", type: ["RestingHeartRate"] }).then(function (result) {
				return result.deviceDataPoints.length > 0;
			})
		},
		categories: ["Summary", "Heart"]
	},
	{
		key: "AppleHealthHeartWalkingHeartRateAverage",
		dataProvider: appleHealthWalkingHeartRateAverage,
		label: "Walking Heart Rate",
		source: "Apple Health",
		color: "#5db37e",
		formatter: function (number: number) {
			return Math.floor(number).toString() + " bpm";
		},
		icon: <FontAwesomeIcon icon={faHeartbeat} />,
		defaultDisabled: true,
		hasAnyData() {
			return myDataHelps.queryDeviceData({ namespace: "AppleHealth", type: ["WalkingHeartRateAverage"] }).then(function (result) {
				return result.deviceDataPoints.length > 0;
			})
		},
		categories: ["Heart"]
	}
];

export default metricsConfiguration;