import { MyDataHelps } from "@careevolution/mydatahelps-ui"
import metricsConfiguration from "./metrics";
const myDataHelps = require("@careevolution/mydatahelps-js").default as MyDataHelps

export default function (): Promise<string[]> {
	return myDataHelps.queryDeviceData({
		namespace: 'Project',
		type: 'AvailableMetrics'
	}).then(function (availableMetricsResult) {
		var metrics: string[] = [];
		if (availableMetricsResult.deviceDataPoints.length) {
			metrics = JSON.parse(availableMetricsResult.deviceDataPoints[0].value);
		}

		var availabilityChecks: Promise<Boolean>[] = [];

		var uncheckedMetrics = metricsConfiguration.filter((m) => metrics.indexOf(m.key) == -1);
		uncheckedMetrics.forEach((m) => {
			availabilityChecks.push(m.hasAnyData());
		});

		if (availabilityChecks.length) {
			var update = false;
			return Promise.all(availabilityChecks).then((values) => {
				values.forEach((v, index) => {
					if (v) {
						update = true;
						metrics.push(uncheckedMetrics[index].key);
					}
				});
				if (update) {
					myDataHelps.persistDeviceData([{
						type: "AvailableMetrics",
						value: JSON.stringify(metrics)
					}]);
				}
				return metrics;
			});
		} else {
			return metrics;
		}
	});
}