import { LoadingIndicator, useInterval } from "@careevolution/mydatahelps-ui";
import React, { useRef } from "react";
import { useEffect } from "react";
import getDayKey from "../../helpers/get-day-key";
import { DailyMetric } from "../../helpers/summarize-metrics";
import { MetricConfiguration } from "../../types/MetricConfiguration";
import MiniDayBarChart from "../MiniDayBarChart";
import "./WeekInReview.css"

export interface WeekInReviewProps {
	displayedDates: Date[];
	selectedDate: Date;
	metricConfigurations: MetricConfiguration[];
	metricValues: { [key: string]: { [key: string]: DailyMetric } };
	wellbeingLookup: { [key: string]: number };
	averageFillPercent: number;
	onDaySelected(date: Date): void;
	onLoadMore(): void;
	loading: boolean;
}

export default function (props: WeekInReviewProps) {
	const weekNavigator = useRef<HTMLDivElement>(null);

	useInterval(() => {
		if (!weekNavigator.current) { return; }
		var contentWidth = weekNavigator.current!.scrollWidth;
		var scrollPosition = weekNavigator.current!.scrollLeft;
		var windowWidth = window.innerWidth;

		var loaders = weekNavigator.current.getElementsByClassName("loading");
		var loaderWidth = 0;
		for (var i = 0; i < loaders.length; i++) {
			loaderWidth += loaders.item(i)!.clientWidth + 8;
		}

		if (((contentWidth + scrollPosition) - loaderWidth) > windowWidth) {
			return;
		}
		props.onLoadMore();
	}, props.loading ? null : 300)

	useEffect(() => {
		if (!weekNavigator.current) { return; }
		weekNavigator.current.scrollLeft = 0;
	}, [props.metricConfigurations]);

	useEffect(() => {
		if (!weekNavigator.current) { return; }
		weekNavigator.current.scrollLeft = weekNavigator.current.scrollLeft;
	}, [props.metricValues])

	return <div className="week-navigator" ref={weekNavigator}>
		{props.displayedDates.map((d) => {
			return <div key={d.getTime()} className="week-navigator-day">
				<MiniDayBarChart
					selected={getDayKey(d) == getDayKey(props.selectedDate)}
					faceValue={props.wellbeingLookup[getDayKey(d)]}
					averageFillPercent={props.averageFillPercent}
					metricConfigurations={props.metricConfigurations}
					metricValues={props.metricValues[getDayKey(d)]}
					date={d}
					onClick={() => props.onDaySelected(d)} />
			</div>
		}
		)}
		{Array.from(Array(14).keys()).map((a) =>
			<div key={a} className="loading week-navigator-day">
				<LoadingIndicator />
			</div>
		)}
	</div>;
}
