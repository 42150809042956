import { add } from "date-fns";
import queryAllDeviceData from "./query-all-device-data";

export default function (startDate: Date, endDate: Date) {
	return queryAllDeviceData({
		namespace: "Fitbit",
		type: "HeartRateZone",
		observedAfter: add(startDate, { days: -1 }),
		observedBefore: add(endDate, { days: 1 })
	}).then(function (ddp) {
		var data: { [key: string]: { [key: string]: number } } = {};
		ddp.forEach((d) => {
			if (d.value == "Out of Range") { return; }
			d.value = d.value.replace(" ", "");
			if (!d.properties["Minutes"]) { return; }
			if (!d.startDate) { return; }
			var dataKey = d.startDate!.substr(0, 10);
			if (!data[dataKey]) {
				data[dataKey] = {};
			}
			data[dataKey][d.value] = parseInt(d.properties["Minutes"]);
		});
		return data;
	});
}
