import { add, formatISO, parseISO } from "date-fns";
import queryAllDeviceData from "./query-all-device-data";

export default function (startDate: Date, endDate: Date) {
	return queryAllDeviceData({
		namespace: "AppleHealth",
		type: "HeartRateVariability",
		observedAfter: add(startDate, { days: -1 }),
		observedBefore: add(endDate, { days: 1 })
	}).then(function (ddp) {
		var dayValues: { [key: string]: number[] } = {};

		ddp.forEach((d) => {
			if (!d.startDate) { return; }
			var day = formatISO(parseISO(d.startDate)).substring(0, 10);
			var value = parseFloat(d.value);
			if (!dayValues[day]) {
				dayValues[day] = [];
			}
			dayValues[day].push(value);
		});

		var data: { [key: string]: { [key: string]: number } } = {};
		while (startDate < endDate) {
			var dayKey = startDate.toISOString().substr(0, 10);
			if (dayValues[dayKey]) {
				data[dayKey] = { "HeartRateVariability": dayValues[dayKey].reduce((a, b) => a + b) / dayValues[dayKey].length };
			}
			startDate = add(startDate, { days: 1 });
		}
		return data;
	});
}