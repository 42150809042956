import { add, format, isSameDay } from "date-fns";
import React from "react";

export interface TitleBarProps {
	date: Date;
}

export default function (props: TitleBarProps) {
	var title = format(props.date, "EEEE")
	if (isSameDay(props.date, new Date())) {
		title = "Today";
	}
	if (isSameDay(props.date, add(new Date(), { days: -1 }))) {
		title = "Yesterday";
	}

	var subtitle = format(props.date, "MMMM d, yyyy");
	return <div className="title">
		{title}, {subtitle}
	</div>;
}