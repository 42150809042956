import React from "react";
import { ActivityMetric, Card, ShinyOverlay } from "@careevolution/mydatahelps-ui";
import "./DayListItem.css"
import { DailyMetric } from "../../helpers/summarize-metrics";
import { MetricConfiguration } from "../../types/MetricConfiguration";
import { getDailyMetricColor } from "../../helpers/get-daily-metric-color";
import { getDailyMetricBadges } from "../../helpers/get-daily-metric-badges";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleDown, faAngleDoubleUp, faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";

export interface DayViewProps {
	metricConfigurations: MetricConfiguration[];
	metricValues: { [key: string]: DailyMetric };
	averageFillPercent: number;
}

export default function (props: DayViewProps) {
	var displayedMetrics = props.metricConfigurations.filter((d) => {
		return props.metricValues[d.key] && props.metricValues[d.key].value > 0;
	});

	if (displayedMetrics.length == 0) {
		return null;
	}

	var badges = getDailyMetricBadges(props.metricConfigurations, props.metricValues);

	return <div className="day-list-item selected">
		{badges.map((b) =>
			<Card key={b.label}>
				<div className="highlight">
					<div key={b.label} className="badge" style={{ backgroundColor: b.color }}>
						<span>
							{b.icon}
						</span>
						<div className={"chevron" + " " + (b.chevron.indexOf("Low") == -1 ? "chevron-up" : "chevron-down")}>
							{b.chevron == "VeryLow" &&
								<FontAwesomeIcon icon={faAngleDoubleDown} />
							}
							{b.chevron == "Low" &&
								<FontAwesomeIcon icon={faAngleDown} />
							}
							{b.chevron == "High" &&
								<FontAwesomeIcon icon={faAngleUp} />
							}
							{b.chevron == "VeryHigh" &&
								<FontAwesomeIcon icon={faAngleDoubleUp} />
							}
						</div>
						<ShinyOverlay />
					</div>
					<div className="highlight-description">
						<div className="highlight-description-title">
							<strong>{b.description} {b.label}</strong>
						</div>
						<strong>{b.difference}</strong> than 30 day average ({b.mean}).
					</div>
				</div>
			</Card>
		)}
		<Card>
			<div className="day-metrics">
				{props.metricConfigurations.map((m) => {
					if (!props.metricValues[m.key]) {
						return null;
					}
					return <ActivityMetric className="day-metric" key={m.key}
						label={m.label}
						averageFillPercent={props.averageFillPercent}
						fillPercent={props.metricValues[m.key].barFillPercent}
						color={getDailyMetricColor(props.metricValues[m.key])}
						icon={m.icon}
						value={m.formatter(props.metricValues[m.key].value)} />
				})}
			</div>
		</Card>
	</div >;
}