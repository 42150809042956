import { add } from "date-fns";
import { MetricConfiguration } from "../types/MetricConfiguration";
import getDayKey from "./get-day-key";
import { mean, std } from "./statistics";

export interface MetricsSummaryResult {
	averageFillPercent: number;
	dailyMetrics: { [key: string]: { [key: string]: DailyMetric } }
}

export interface DailyMetric {
	value: number;
	mean: number;
	stdev: number | null;
	variance: number | null;
	barFillPercent: number;
}

function get30DayStatistics(date: Date, metricValuesByDay: { [key: string]: { [key: string]: number } }, metricConfigurationKey: string) {
	var previous30DayValues: number[] = [];

	for (var i = 1; i <= 30; i++) {
		var lookupDate = add(date, { days: -1 * i });
		var data = metricValuesByDay[getDayKey(lookupDate)][metricConfigurationKey];
		if (!data || data == 0) { continue; }
		previous30DayValues.push(data);
	}

	return {
		mean: previous30DayValues.length > 0 ? mean(previous30DayValues) : metricValuesByDay[getDayKey(date)][metricConfigurationKey],
		stdev: previous30DayValues.length > 4 ? std(previous30DayValues) : null
	};
}

function getBaseMetricsForDay(date: Date, metricValuesByDay: { [key: string]: { [key: string]: number } }, metricConfigurations: MetricConfiguration[]): { [key: string]: DailyMetric } {
	var result: { [key: string]: DailyMetric } = {};
	var dayKey = getDayKey(date);
	var data = metricValuesByDay[dayKey];
	if (!data) { return {}; }

	metricConfigurations.forEach(function (m) {
		if (data && data[m.key] && data[m.key] != 0) {
			var statistics = get30DayStatistics(date, metricValuesByDay, m.key);
			result[m.key] = {
				barFillPercent: (data[m.key] / statistics.mean) / 2,
				mean: statistics.mean,
				stdev: statistics.stdev,
				value: data[m.key],
				variance: statistics.stdev ? ((data[m.key] - statistics.mean) / statistics.stdev) : null
			};
		}
	});

	return result;
}

export function summarizeMetrics(dates: Date[], metricValuesByDay: { [key: string]: { [key: string]: number } }, metricConfigurations: MetricConfiguration[]): MetricsSummaryResult {
	var maxBarFillPercent = 1;
	var dailyMetricsLookup: { [key: string]: { [key: string]: DailyMetric } } = {};

	dates.forEach((d) => {
		var baseMetrics = getBaseMetricsForDay(d, metricValuesByDay, metricConfigurations);
		metricConfigurations.forEach((m) => {
			if (baseMetrics[m.key] && baseMetrics[m.key].barFillPercent > maxBarFillPercent) {
				maxBarFillPercent = baseMetrics[m.key].barFillPercent;
			}
		});
		dailyMetricsLookup[getDayKey(d)] = baseMetrics;
	});

	var squishingMultiplier = 1 / maxBarFillPercent;
	//maximize squishing at 0.5, otherwise the graph can get really messed up by big jumps
	if (squishingMultiplier < 0.5) {
		squishingMultiplier = 0.5;
	}
	var averageFillPercent = 0.5;
	averageFillPercent = averageFillPercent * squishingMultiplier;

	dates.forEach((d) => {
		var metricsForDay = dailyMetricsLookup[getDayKey(d)];
		if (!metricsForDay) { return; }
		metricConfigurations.forEach((m) => {
			if (metricsForDay[m.key]) {
				metricsForDay[m.key].barFillPercent = metricsForDay[m.key].barFillPercent * squishingMultiplier;
				if (metricsForDay[m.key].barFillPercent > 1) {
					metricsForDay[m.key].barFillPercent = 1;
				}
			}
		});
	});

	return {
		averageFillPercent: averageFillPercent,
		dailyMetrics: dailyMetricsLookup
	}
}