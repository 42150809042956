import { Face, ShinyOverlay } from '@careevolution/mydatahelps-ui';
import { faAngleDoubleDown, faAngleDoubleUp, faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns';
import React, { MouseEventHandler, ReactElement } from 'react';
import { getDailyMetricBadges } from '../../helpers/get-daily-metric-badges';
import { getDailyMetricColor } from '../../helpers/get-daily-metric-color';
import { DailyMetric } from '../../helpers/summarize-metrics';
import { MetricConfiguration } from '../../types/MetricConfiguration';
import "./MiniDayBarChart.css"

export interface MiniDayBarChartProps {
	averageFillPercent: number;
	date: Date;
	faceValue?: number;
	metricConfigurations: MetricConfiguration[];
	metricValues: { [key: string]: DailyMetric };
	selected: boolean;
	onClick?: MouseEventHandler;
}

export default function (props: MiniDayBarChartProps) {
	var width = (100 / props.metricConfigurations.length);

	var badges = getDailyMetricBadges(props.metricConfigurations, props.metricValues);

	return <div key={props.date.getTime()} className={"pem-mini-day-bar-chart " + (props.selected ? "selected" : "")} onClick={props.onClick}>
		{props.faceValue &&
			<div className="face-wrapper">
				<Face faceValue={props.faceValue} selected={true} />
			</div>
		}
		<div className="badges">
			{badges.map((b, index) =>
				<div key={b.label} className="badge" style={{ backgroundColor: b.color }}>
					<span>
						{b.icon}
					</span>
					<div className={"chevron" + " " + (b.chevron.indexOf("Low") == -1 ? "chevron-up" : "chevron-down")}>
						{b.chevron == "VeryLow" &&
							<FontAwesomeIcon icon={faAngleDoubleDown} />
						}
						{b.chevron == "Low" &&
							<FontAwesomeIcon icon={faAngleDown} />
						}
						{b.chevron == "High" &&
							<FontAwesomeIcon icon={faAngleUp} />
						}
						{b.chevron == "VeryHigh" &&
							<FontAwesomeIcon icon={faAngleDoubleUp} />
						}
					</div>
					<ShinyOverlay />
				</div>
			)}
		</div>

		<div className="day-bar-wrapper">
			<div className="average-marker" style={{ bottom: props.averageFillPercent * 100 + "%" }}></div>
			{props.metricConfigurations.filter((m) => props.metricValues[m.key]).map((b, index) =>
				<div key={index} className="bar" style={{ backgroundColor: getDailyMetricColor(props.metricValues[b.key]), height: props.metricValues[b.key].barFillPercent * 100 + "%", width: width + "%", left: width * index + "%" }}>
					<ShinyOverlay />
				</div>
			)}
		</div>
		<div className="day">{format(props.date, "E")} <br /> {format(props.date, "M/d")}</div>
	</div>;
}