import { add } from "date-fns";
import { MetricConfiguration } from "../types/MetricConfiguration";
import { wellbeingDataProvider } from "./daily-device-data-providers";
import getDayKey from "./get-day-key";

interface MetricsQueryResult {
	metricsByDay: { [key: string]: { [key: string]: number } };
	wellbeingByDay: { [key: string]: number };
}

export function queryMetrics(startDate: Date, endDate: Date, metricConfigurations: MetricConfiguration[]): Promise<MetricsQueryResult> {
	var promises = metricConfigurations.map((p) => p.dataProvider(startDate, endDate));
	promises.push(wellbeingDataProvider(startDate, endDate));
	var result: MetricsQueryResult = {
		metricsByDay: {},
		wellbeingByDay: {}
	};
	return Promise.all(promises).then((values) => {
		var date = startDate;
		while (date < endDate) {
			var dayKey = getDayKey(date);

			result.metricsByDay[dayKey] = {};
			metricConfigurations.forEach((m, index) => {
				if (values[index][dayKey]) {
					//TODO: make the things just return a single value
					var combinedValue = Object.keys(values[index][dayKey]).map((s) => values[index][dayKey][s]).reduce((partialSum, a) => partialSum + a, 0);
					result.metricsByDay[dayKey][m.key] = combinedValue;
				}
			});

			if (values[values.length - 1][dayKey] && values[values.length - 1][dayKey]["Wellbeing"]) {
				result.wellbeingByDay[dayKey] = values[values.length - 1][dayKey]["Wellbeing"];
			}
			date = add(date, { days: 1 });
		}
		return result;
	});
}