import { add, formatISO, parseISO } from "date-fns";
import queryAllDeviceData from "./query-all-device-data";

export default function (startDate: Date, endDate: Date) {
	return queryAllDeviceData({
		namespace: "Fitbit",
		type: ["SleepLevelRem", "SleepLevelLight", "SleepLevelDeep", "SleepLevelAsleep"],
		observedAfter: add(startDate, { days: -1 }),
		observedBefore: add(endDate, { days: 1 })
	}).then(function (ddp) {
		var data: { [key: string]: { [key: string]: number } } = {};
		ddp.forEach((d) => {
			if (!d.observationDate) { return; }
			var dataKey = formatISO(add(parseISO(d.observationDate)!, { hours: 6 })).substr(0, 10);
			if (!data[dataKey]) {
				data[dataKey] = {};
			}
			data[dataKey][d.type] = parseFloat(d.value);
		});
		return data;
	});
}
