import { ReactElement } from "react";
import { MetricConfiguration } from "../types/MetricConfiguration";
import { getDailyMetricColor } from "./get-daily-metric-color";
import { DailyMetric } from "./summarize-metrics";

export interface DailyMetricBadge {
	icon: ReactElement;
	chevron: "VeryLow" | "Low" | "High" | "VeryHigh";
	label: string;
	color: string;
	variance: number;
	value: string;
	mean: string;
	description: string;
	difference: string;
}

function getChevron(variance: number) {
	if (variance <= -2) {
		return "VeryLow";
	}
	if (variance <= -1) {
		return "Low";
	}
	if (variance >= 2) {
		return "VeryHigh";
	}
	if (variance >= 1) {
		return "High";
	}
	throw Error("Variance out of range");
}


function getDescription(variance: number) {
	if (variance <= -2) {
		return "Significantly Lower";
	}
	if (variance <= -1) {
		return "Lower";
	}
	if (variance >= 2) {
		return "Significantly Higher";
	}
	if (variance >= 1) {
		return "Higher";
	}
	throw Error("Variance out of range");
}


export function getDailyMetricBadges(metricConfigurations: MetricConfiguration[], metricValues: { [key: string]: DailyMetric }) {
	var result: DailyMetricBadge[] = [];
	metricConfigurations.forEach(function (m) {
		var metricValue = metricValues[m.key];
		if (!metricValue) { return; }
		if (!metricValue.variance || Math.abs(metricValue.variance) < 1) { return; }

		var badge: DailyMetricBadge = {
			icon: m.icon,
			chevron: getChevron(metricValue.variance),
			label: m.label,
			color: getDailyMetricColor(metricValue),
			variance: metricValue.variance,
			value: m.formatter(metricValue.value),
			mean: m.formatter(metricValue.mean),
			description: getDescription(metricValue.variance),
			difference: m.formatter(Math.abs(metricValue.value - metricValue.mean)) + (((metricValue.value - metricValue.mean) < 0) ? " less" : " more")
		}
		result.push(badge);
	});
	return result.sort((r1, r2) => Math.abs(r2.variance) - Math.abs(r1.variance));
}