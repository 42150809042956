import { add } from "date-fns";
import queryAllDeviceData from "./query-all-device-data";

export default function (startDate: Date, endDate: Date) {
	return queryAllDeviceData({
		namespace: "Fitbit",
		type: "RestingHeartRate",
		observedAfter: add(startDate, { days: -1 }),
		observedBefore: add(endDate, { days: 1 })
	}).then(function (result) {
		var data: { [key: string]: { [key: string]: number } } = {};
		result.forEach((d) => {
			if (!d.startDate) { return; }
			if (parseInt(d.value) == 0) { return; }

			var dayKey = d.startDate!.substr(0, 10);
			if (!data[dayKey]) {
				data[dayKey] = {};
			}
			data[dayKey]["RestingHeartRate"] = parseInt(d.value);
		});
		return data;
	});
}
